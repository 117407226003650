import React, { useEffect, useState } from 'react';
import { KnockFeedProvider as NativeKnockFeedProvider } from '@knocklabs/react-notification-feed';
import { useAuth } from '@hooks/useAuth';
import { KNOCK_FEED_ID, KNOCK_PUBLIC_KEY } from '@config/constants';
import { useCurrentWorkspace } from '@hooks/workspace';
import { getKnockUserToken } from '@api/Profile';
import moment from 'moment';

interface KnockFeedProviderProps {
  children: React.ReactElement;
}

interface KnockIdentity {
  readonly userId: string;
  readonly token: string;
  readonly expiresAt: Date;
}

export function KnockFeedProvider({ children }: KnockFeedProviderProps) {
  const { user } = useAuth();
  const [currentWorkspace] = useCurrentWorkspace(false);
  const [identity, setIdentity] = useState<KnockIdentity>();
  const now = new Date();
  const tokenExpired =
    !!identity && identity.expiresAt.valueOf() - now.valueOf() < 60 * 1000;
  useEffect(() => {
    if (!user?.id) {
      setIdentity(undefined);
      return;
    }
    let cancelled = false;
    (async () => {
      const { data } = await getKnockUserToken();
      if (cancelled) return;
      setIdentity({
        userId: data.userId,
        token: data.token,
        expiresAt: moment(data.expiresAt).toDate()
      });
    })();
    return () => {
      cancelled = true;
    };
  }, [user?.id, tokenExpired]);
  if (!identity || !currentWorkspace) return null;
  return (
    <NativeKnockFeedProvider
      apiKey={KNOCK_PUBLIC_KEY}
      feedId={KNOCK_FEED_ID}
      userId={identity.userId}
      userToken={identity.token}
      tenant={currentWorkspace.id}
    >
      {children}
    </NativeKnockFeedProvider>
  );
}
