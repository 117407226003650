import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown, Menu, Button } from 'antd';
import PlanTag from '@components/PlanTag';
import { useOrganization } from '@components/OrganizationBoundary';
import { ReactComponent as LeaveSvg } from '@assets/icons/leave.svg';
import { ReactComponent as ArrowSvg } from '@assets/icons/arrow-down.svg';
import './UserMenuDropdown.less';
import { useDispatch } from 'react-redux';
import { toggleBillingModal } from '@redux/actions/modalAction';
import UserAvatar from '@components/UserAvatar';
import { useAuth } from '@hooks/useAuth';

function UserMenuDropdown() {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const selectedKey = location.pathname.substring(1).split('/')?.[0];
  const { user } = useAuth(true, 'internal');
  const { currentOrganization } = useOrganization();
  const menu = (
    <>
      <div className="dropdown_overlay">
        <div className="dropdown_overlay__basic">
          <div className="user_info">
            <div className="user_info__avatar">
              <UserAvatar
                isActive={true}
                size="large"
                src={user.pictureUrl || ''}
                userEmail={user.email}
                userName={user.name}
              />
            </div>
            <div className="user_info__data">
              <div className="user_name">{user.name}</div>
              <div className="user_email">{user.email}</div>
            </div>
          </div>
        </div>
        {!!currentOrganization.entity && (
          <div className="dropdown_overlay__plan">
            <p className="plan_info">
              Your StreamWork plan{' '}
              <PlanTag
                planName={currentOrganization.entity?.planName ?? 'Free'}
              />
            </p>
            {currentOrganization.entity?.planType !== 'enterprise' && (
              <Button
                className="upgrade_plan"
                type="link"
                onClick={() => {
                  if (!currentOrganization.entity?.owner.me) return;
                  dispatch(toggleBillingModal({ visible: true }));
                }}
              >
                Upgrade
              </Button>
            )}
          </div>
        )}
      </div>
      <Menu
        selectedKeys={[selectedKey]}
        onClick={() => setIsDropdownVisible(false)}
      >
        <Menu.Divider />
        {currentOrganization.entity?.permissions.inviteAdmin && (
          <Menu.Item key="admin-settings">
            <Link to="/admin-settings">
              <div className="title">Admin settings</div>
              <div className="description">Billing, Organization</div>
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key="profile-settings">
          <Link to="/profile-settings">
            <div className="title">Profile settings</div>
            <div className="description">Edit profile, Notifications</div>
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="2"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <div className="sign_out">
            <LeaveSvg /> Sign out
          </div>
        </Menu.Item>
      </Menu>
    </>
  );
  return (
    <Dropdown
      overlay={menu}
      overlayClassName="user_menu_overlay"
      visible={isDropdownVisible}
      onVisibleChange={(v) => setIsDropdownVisible(v)}
      trigger={['click']}
    >
      <div className="dropdown_child">
        <div className="dropdown_child__avatar">
          <UserAvatar
            isActive={true}
            size="large"
            src={user.pictureUrl || ''}
            userEmail={user.email}
            userName={user.name}
          />
        </div>
        <div className="dropdown_child__user">
          <span
            className="user_name"
            style={!currentOrganization.entity?.owner.me ? { top: 0 } : {}}
          >
            <span>{user.name}</span>
            <ArrowSvg />
          </span>
          {currentOrganization.entity?.permissions.inviteAdmin && (
            <span className="user_role">Admin</span>
          )}
        </div>
      </div>
    </Dropdown>
  );
}

export default UserMenuDropdown;
