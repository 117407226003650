import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import PageLayout from '@layouts/PageLayout';
import MediaViewerLayout from '@layouts/MediaViewerLayout';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import CampaignCreatedModal from '@components/Modals/CampaignCreatedModal';
import CampaignFolderCreationModal from '@components/Modals/CampaignFolderCreationModal';
import CampaignInfoModal from '@components/Modals/CampaignInfoModal';
import DeleteEntityModal from '@components/Modals/DeleteEntityModal';
import DeleteWorkspaceOrganizationModal from '@components/Modals/DeleteWorkspaceOrganizationModal';
import InviteToCampaignModal from '@components/Modals/InviteToCampaignModal';
import InviteToWorkspaceModal from '@components/Modals/InviteToWorkspaceModal';
import LeaveEntityModel from '@components/Modals/LeaveEntityModel';
import ManageAssetVersionsModal from '@components/Modals/ManageAssetVersionsModal';
import ManageFolderModal from '@components/Modals/ManageFolderModal';
import MembersModal from '@components/Modals/MembersModal/MembersModal';
import MoveAssetToPrivateFolderModal from '@components/Modals/MoveAssetToPrivateFolderModal';
import ReachedEntityLimitModal from '@components/Modals/ReachedEntityLimitModal';
import RenameEntityModal from '@components/Modals/RenameEntityModal';
import ShareAssetModal from '@components/Modals/ShareAssetModal';
import ToggleFolderAccessModal from '@components/Modals/ToggleFolderAccessModal';
import UploadAssetModal from '@components/Modals/UploadAssetModal';
import WorkspaceCreationModal from '@components/Modals/WorkspaceCreationModal';
import Dashboard from '@pages/Dashboard';
import Campaigns from '@pages/Campaigns';
import Assets from '@pages/Assets';
import Tasks from '@pages/Tasks';
import AdminSettings from '@pages/AdminSettings/AdminSettings';
import ProfileSettings from '@pages/ProfileSettings';
import MediaViewer from '@pages/MediaViewer';
import NewAssetWithWorkflowModal from '@components/Modals/NewAssetWithWorkflowModal';
import {
  BillingModal,
  CancelSubscriptionModal,
  ChangePlanDetailsModal
} from '@components/Modals/BillingModal';
import {
  EntityAccessHasBeenChanged,
  NewWorkspaceToast
} from '@components/Toasts';
import RenameOrganizationModal from '@components/Modals/RenameOrganizationModal';
import { toast } from 'react-toastify';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useAuth } from '@hooks/useAuth';
import {
  useWebSocketDebouncedSubscription,
  useWebSocketSubscription,
  WebSocketClientProvider
} from '@hooks/useWebSocketClient';
import { useOrganization } from '@components/OrganizationBoundary';
import { useDispatch } from 'react-redux';
import { getWorkspace } from '@api/Workspace';
import { WorkspaceActionTypes } from '@redux/types/workspaceType';
import { setWorkspaces, updateWorkspace } from '@redux/actions/workspaceAction';

import ApprovalModal from '@components/Modals/ApprovalModal';
import { useTypedSelector } from '@hooks';
import { WorkspaceProvider } from '@components/WorkspaceBoundary';
import { message } from 'antd';
import AssetStackErrorModal from '@components/Modals/AssetStackErrorModal';
import { ErrorPage404, ErrorPage500 } from '@pages/ErrorPage';
import UploadingAssetsProcess from '@components/UploadingAssetsProcess';
import { AssetsUppyProvider } from '@context/AssetsUppyContext';
import TaskModal from '@components/Tasks/TaskModal';
import { TaskAttachmentsUppyProvider } from '@context/TaskAttachmentsUppyContext';
import { useTaskWebSocketSubscription } from '@hooks/tasks';
import { ErrorMessageProps } from 'src/AuthorizedApp';
import { useFetch } from '@hooks/useFetch';
import {
  fetchOrganizationSeatsUsed,
  fetchOrganizationStorageUsed,
  fetchOrganizationSubscription
} from '@redux/actions/organizations';
import { organizationItemStateSelector } from '@redux/selectors/organizations';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { useOrganizationWebSocketSubscription } from '@hooks/organization';
import Start from '@pages/Start/Start';
import CombineAssetsModal from '@components/Assets/CombineAssetsModal';
import CopyAssetVersionModal from '@components/Assets/CopyAssetVersionModal';
import WorkspaceMemberGroupInviteModal from '@components/Modals/WorkspaceMemberGroupInviteModal';
import WorkspaceMemberGroupCreateModal from '@components/Modals/WorkspaceMemberGroupCreateModal';
import WorkspaceMemberGroupModal from '@components/Modals/WorkspaceMemberGroupModal';

type AppPagesProps = {
  errorMessage: ErrorMessageProps | undefined;
  demoCampaignId: string | undefined;
};

function AppPages({ errorMessage, demoCampaignId }: AppPagesProps) {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth(true, 'internal');
  const { currentOrganization } = useOrganization(false);
  const [currentWorkspace] = useCurrentWorkspace(false);

  useEffect(() => {
    const redirectState = searchParams.get('state');
    if (redirectState) {
      let parsedState: any = {};
      try {
        parsedState = JSON.parse(redirectState);
      } catch {
        console.log('errorParseRedirectStateParams');
      }
      if (parsedState.redirectUrl) {
        navigate(parsedState.redirectUrl);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (!errorMessage) return;
    const content = (
      <div className="upload-error">
        {errorMessage.title && (
          <div className="upload-error--name">{errorMessage.title} </div>
        )}

        <div className="upload-error--description">
          {errorMessage.description}
        </div>
      </div>
    );
    message.error({
      content,
      className: 'message-dark-modal',
      duration: 10
    });
  }, [errorMessage]);

  const showWelcomeToast = useCallback((userName, workspaceName) => {
    toast(
      <NewWorkspaceToast
        userName={userName}
        workspaceName={workspaceName}
        closeToast={() => null}
      />,
      {
        hideProgressBar: true,
        className: 'new_workspace_toast'
      }
    );
  }, []);

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.organizationId !== currentOrganization?.entity?.id) return;
    if (msg.causedByMe) return;
    const {
      data: { workspace }
    } = await getWorkspace({
      workspaceId: msg.payload.workspaceId
    });
    dispatch({
      type: WorkspaceActionTypes.CREATE_NEW_WORKSPACE,
      payload: { workspace }
    });
  }, 'workspace-created');

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.organizationId !== currentOrganization?.entity?.id) return;
    if (msg.causedByMe) return;

    dispatch({
      type: WorkspaceActionTypes.REMOVE_WORKSPACE,
      payload: msg.payload.workspaceId
    });
  }, 'workspace-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.organizationId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.payload.reason === 'name-changed') {
        dispatch({
          type: WorkspaceActionTypes.RENAME_WORKSPACE,
          payload: {
            organizationId: lastMessage.payload.organizationId,
            workspaceId: lastMessage.payload.workspaceId,
            name: lastMessage.payload.newName
          }
        });
        return;
      }
      const {
        data: { workspace }
      } = await getWorkspace({
        workspaceId: lastMessage.payload.workspaceId
      });
      dispatch(updateWorkspace(workspace));
    },
    1000,
    (msg) => {
      if (msg.payload.organizationId !== currentOrganization?.entity?.id)
        return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'workspace-updated'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.workspaceId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { reason, workspaceId } = lastMessage.payload;
      if (workspaceId === currentWorkspace?.id)
        toast(<EntityAccessHasBeenChanged entity="workspace" />, {
          hideProgressBar: true,
          style: { height: 66, width: 420 }
        });
      try {
        const {
          data: { workspace }
        } = await getWorkspace({
          workspaceId: lastMessage.payload.workspaceId
        });
        if (reason === 'maybe-lost-access' || reason === 'role-changed') {
          dispatch(updateWorkspace(workspace));
          return;
        }
        dispatch({
          type: WorkspaceActionTypes.CREATE_NEW_WORKSPACE,
          payload: { workspace }
        });
      } catch (e) {
        if (reason === 'maybe-lost-access') {
          dispatch({
            type: WorkspaceActionTypes.REMOVE_WORKSPACE,
            payload: {
              workspaceId: lastMessage.payload.workspaceId,
              organizationId: lastMessage.payload.organizationId
            }
          });
        }
      }
    },
    1000,
    (msg) => {
      if (msg.payload.organizationId !== currentOrganization?.entity?.id)
        return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'workspace-access-changed'
  );

  useTaskWebSocketSubscription();
  useOrganizationWebSocketSubscription();

  return (
    <>
      <Routes>
        <Route element={<MediaViewerLayout />}>
          <Route path="media-viewer">
            <Route
              path=":assetId"
              element={
                <MediaViewer
                  commentMentions={
                    !!currentOrganization?.entity?.features.commentMentions
                  }
                />
              }
            />
            <Route
              path=":assetId/:variantId"
              element={
                <MediaViewer
                  commentMentions={
                    !!currentOrganization?.entity?.features.commentMentions
                  }
                />
              }
            />
          </Route>
        </Route>
        <Route element={<PageLayout />}>
          <Route path="start">
            <Route index element={<Start />} />
            <Route path=":workspaceId" element={<Start />} />
          </Route>
          <Route path="dashboard">
            <Route index element={<Dashboard />} />
            <Route path=":workspaceId" element={<Dashboard />} />
          </Route>
          <Route path="campaigns/*" element={<Campaigns />} />
          <Route path="assets/*" element={<Assets />} />
          <Route path="tasks/*" element={<Tasks />} />
          <Route path="admin-settings" element={<AdminSettings />}>
            <Route path=":tab" element={null} />
          </Route>
          <Route path="profile-settings" element={<ProfileSettings />}>
            <Route path=":tab" element={null}>
              <Route path=":workspaceId" element={null} />
            </Route>
          </Route>
          <Route index element={<Navigate to="campaigns/all" replace />} />
        </Route>
        <Route path="error-404" element={<ErrorPage404 />} />
        <Route path="error-500" element={<ErrorPage500 />} />
        <Route path="*" element={<Navigate to="/error-404" />} />
      </Routes>
      <TaskModal />
      <NewAssetWithWorkflowModal />
      <WorkspaceCreationModal />
      <InviteToWorkspaceModal />
      <WorkspaceMemberGroupInviteModal />
      <WorkspaceMemberGroupCreateModal />
      <WorkspaceMemberGroupModal />
      <MembersModal />
      <BillingModal />
      <DeleteWorkspaceOrganizationModal />
      <LeaveEntityModel />
      <ShareAssetModal />
      <RenameEntityModal />
      <DeleteEntityModal />
      <CampaignInfoModal />
      <CampaignFolderCreationModal />
      <CampaignCreatedModal />
      <InviteToCampaignModal />
      <RenameOrganizationModal />
      <MoveAssetToPrivateFolderModal />
      <ManageAssetVersionsModal />
      <ChangePlanDetailsModal />
      <CancelSubscriptionModal />
      <ReachedEntityLimitModal />
      <ApprovalModal />
      <UploadAssetModal />
      <AssetStackErrorModal />
      <ManageFolderModal />
      <ToggleFolderAccessModal />
      <UploadingAssetsProcess />
      <CombineAssetsModal />
      <CopyAssetVersionModal />
    </>
  );
}

type AppPagesContainerProps = AppPagesProps & {
  loader: ReactElement;
};

export default function AppPagesContainer({
  loader,
  ...props
}: AppPagesContainerProps) {
  const { user } = useAuth(true, 'internal');
  const { currentOrganization } = useOrganization(false);
  const organizationId = currentOrganization?.entity?.id;
  const [dataLoading, setDataLoading] = useState(true);
  const dispatch = useTypedDispatch();
  const workspaceList = useTypedSelector(
    ({ workspace }) => workspace.workspaceList
  );
  useFetch({
    disabled: !currentOrganization?.entity?.owner.me || !organizationId,
    key: `organizations-subscription-${organizationId}`,
    selector: (state) =>
      organizationItemStateSelector(state, {
        organizationId: organizationId as string
      }).subscription.fetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationSubscription({
          fetchType,
          organizationId: organizationId as string
        })
      )
  });
  useFetch({
    disabled: !organizationId,
    key: `organizations-seats-used-${organizationId}`,
    selector: (state) =>
      organizationItemStateSelector(state, {
        organizationId: organizationId as string
      }).seatsCount.countFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationSeatsUsed({
          fetchType,
          organizationId: organizationId as string
        })
      )
  });
  useFetch({
    disabled: !organizationId,
    key: `organizations-storage-used-${organizationId}`,
    selector: (state) =>
      organizationItemStateSelector(state, {
        organizationId: organizationId as string
      }).assetsSummarySize.sizeFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationStorageUsed({
          fetchType,
          organizationId: organizationId as string
        })
      )
  });
  useEffect(() => {
    (async () => {
      await setWorkspaces()(dispatch);
      setDataLoading(false);
    })();
  }, [user.id, dispatch, setDataLoading]);
  if (dataLoading) {
    return loader;
  }
  return (
    <WebSocketClientProvider>
      <WorkspaceProvider workspaces={workspaceList}>
        <AssetsUppyProvider>
          <TaskAttachmentsUppyProvider>
            <AppPages {...props} />
          </TaskAttachmentsUppyProvider>
        </AssetsUppyProvider>
      </WorkspaceProvider>
    </WebSocketClientProvider>
  );
}
