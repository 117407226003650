import React from 'react';
import { ErrorPage } from '@pages/ErrorPage/index';
import { ReactComponent as AlienShipIcon } from '@assets/icons/alien-ship-icon.svg';
import {
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType
} from 'react-router-dom';

function ErrorPage404() {
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const location = useLocation();
  const isErrorRoute = location.pathname === '/error-404';
  const currentKey = location.key;
  return (
    <ErrorPage
      content={
        <div style={{ textAlign: 'center', position: 'relative' }}>
          <AlienShipIcon className="alien-ship-image" />
          <div className="text-container" style={{ marginTop: 56 }}>
            <span>Oops...</span>
            <span style={{ maxWidth: 384 }}>
              This page is no longer working. Return to previous page?
            </span>
          </div>
        </div>
      }
      buttonCallback={() => {
        if (navigationType === NavigationType.Push) {
          navigate(isErrorRoute ? -2 : -1);
        } else if (currentKey !== 'default') {
          navigate(-1);
        } else {
          navigate('/campaigns/all', { replace: true });
        }
      }}
    />
  );
}

export default ErrorPage404;
