import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  forwardRef,
  useMemo
} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import classNames from 'classnames';
import { AssetItemDto, AVMetadataInfoDto, SimpleFolderItemDto } from '@api/Api';
import Loader from '@components/Loader';
import { useDispatch } from 'react-redux';
import {
  toggleShareAssetModal,
  toggleRenameEntityModal,
  toggleEntityDeletionModal,
  toggleMoveAssetToPrivateFolder,
  toggleManageAssetVersionsModal,
  toggleApprovalModal
} from '@redux/actions/modalAction';
import { Avatar, Button, Checkbox, Dropdown, Menu, Tooltip } from 'antd';
import { ReactComponent as FavoriteSVG } from '@assets/icons/favorite.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as PrivateEye } from '@assets/icons/eye-private.svg';
import { ReactComponent as RestoreAssetSvg } from '@assets/icons/restore-asset-icon.svg';
import { ReactComponent as CommentSvg } from '@assets/icons/comment-icon.svg';
import {
  moveAssetToFolder,
  restoreDeletedAssetAction,
  setFavoriteAssetAction
} from '@redux/actions/assetsAction';
import { updateAssetsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import { assetGetSimpleFoldersList } from '@api/Asset';
import { formatDuration } from '@helpers/formatDuration';
import moment from 'moment';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import AssetVersionsList from '@components/Assets/AssetVersionsList';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { useAuth } from '@hooks/useAuth';
import Status from '@components/Approval/Status';
import { download } from '@helpers/download';
import { AssetsActionTypes } from '@redux/types/assetsType';
import { ActionToast } from '@components/Toasts';
import { toast } from 'react-toastify';
import { useOrganization } from '@components/OrganizationBoundary';
import LottieComponent from '@components/Lottie';
import DueDate from '@components/Tasks/DueDate';
import { useCurrentTime } from '@hooks/useCurrentTime';
import { isDateSameOrBefore } from '@helpers/compareDueDate';
import './AssetCard.less';
import { hashedColor } from '@helpers/hashedColor';
import UserAvatarLetters from '@helpers/userAvatarLetters';

const AssetCard = forwardRef(
  (
    {
      asset,
      selectedOne,
      canShare,
      isShowCompany,
      page
    }: {
      asset: AssetItemDto;
      selectedOne: boolean;
      canShare?: boolean;
      isShowCompany?: boolean;
      page: string;
    },
    ref: any
  ) => {
    const now = useCurrentTime();
    const dispatch = useDispatch();
    const mixpanel = useMixpanel();
    const { user } = useAuth(true, 'internal');
    const navigate = useNavigate();
    const location = useLocation();

    const scrubRef = useRef<any>();
    const scrubLineRef = useRef<any>();
    const assetRef = useRef<any>();
    const [foldersList, setFoldersList] = useState<SimpleFolderItemDto[]>([]);
    const [isCardMenuVisible, setIsCardMenuVisible] = useState<boolean>(false);
    const [scrubsVisible, setScrubsVisible] = useState<boolean>(false);
    const { currentOrganization } = useOrganization();
    const [scrubsUrl, setScrubsUrl] = useState<string>('');
    const [frameWidth, setFrameWidth] = useState<number>(0);
    const [frameHeight, setFrameHeight] = useState<number>(0);
    const [framesCount, setFramesCount] = useState<number>(0);
    useEffect(() => {
      if (!isCardMenuVisible) return;
      (async () => {
        const {
          data: { list }
        } = await assetGetSimpleFoldersList({
          campaignId: asset.campaignId,
          workspaceId: asset.workspaceId
        });
        setFoldersList(list);
      })();
    }, [isCardMenuVisible]);
    useEffect(() => {
      if (asset?.asset?.versions[0].metadata.type === 'video') {
        setScrubsUrl(asset.asset?.versions[0].metadata.scrub.url || '');
        setFrameWidth(
          asset.asset?.versions[0].metadata.scrub.frames?.dimensions.width || 0
        );
        setFrameHeight(
          asset.asset?.versions[0].metadata.scrub.frames?.dimensions.height || 0
        );
        setFramesCount(
          asset.asset?.versions[0].metadata.scrub.frames?.count || 0
        );
      }
    }, [asset]);
    const currentStageDeadline = useMemo(() => {
      if (!asset.asset?.versions[0].approvalWorkflow) return;
      const { approvalWorkflow } = asset.asset.versions[0];
      const { currentStageId } = approvalWorkflow;
      const currentStage = approvalWorkflow.stages.find(
        (it) => it.id === currentStageId
      );
      return currentStage?.deadline;
    }, [asset.asset?.versions]);

    const isPastDueDate = useMemo(() => {
      if (!currentStageDeadline) return;
      return isDateSameOrBefore({
        dateToCompare: currentStageDeadline,
        baseDate: now
      });
    }, [now, currentStageDeadline]);

    const onMouseEnter = useCallback(() => {
      if (asset?.asset?.versions[0].metadata.type !== 'video') return;
      setScrubsVisible(true);
      scrubLineRef.current.style.display = 'block';
    }, [setScrubsVisible, asset]);

    const onMouseLeave = useCallback(() => {
      if (asset?.asset?.versions[0].metadata.type !== 'video') return;
      setScrubsVisible(false);
      scrubLineRef.current.style.display = 'none';
    }, [setScrubsVisible, asset]);

    const onMouseMove = useCallback(
      async (e) => {
        if (asset?.asset?.versions[0].metadata.type !== 'video') return;
        if (asset?.asset?.versions[0].metadata.preview.status === 'pending')
          return;
        const canvasEl = scrubRef.current;
        if (!canvasEl) return;
        const rect = e.target.getBoundingClientRect();
        const stepWidth = rect.width / framesCount;
        const frameIndex = Math.floor((e.clientX - rect.left) / stepWidth);
        const img = new Image();
        canvasEl.frameIndex = frameIndex;

        img.src = scrubsUrl;
        img.onload = () => {
          const canvasEl = scrubRef.current;
          if (!canvasEl || canvasEl.frameIndex !== frameIndex) return;

          if (scrubLineRef?.current) {
            scrubLineRef.current.style.left = `${Math.floor(
              e.clientX - rect.left
            )}px`;
          }

          const ctx = scrubRef.current.getContext('2d');

          ctx.drawImage(
            img,
            0,
            frameIndex * frameHeight,
            frameWidth,
            frameHeight,
            0,
            0,
            frameWidth,
            frameHeight
          );
        };
      },
      [
        scrubsUrl,
        frameWidth,
        frameHeight,
        scrubLineRef,
        framesCount,
        scrubRef,
        asset
      ]
    );

    const preparePreview = useMemo(() => {
      const res = assetPreviewThumbnails({
        url: asset.asset?.versions[0].metadata.preview?.url || '',
        status: asset.asset?.versions[0].metadata.preview?.status || '',
        type: asset.asset?.versions[0].metadata.type || '',
        format: 'img'
      });

      if (res === 'pending') return 'pending';
      if (typeof res === 'string')
        return <img draggable={false} src={res || ''} alt="" />;
      return res;
    }, [asset.asset?.versions]);

    const awFeatureEnabled =
      !!asset.asset?.versions[0].approvalWorkflow ||
      !!currentOrganization.entity?.features.approvalRouting;
    const canManageAW =
      asset.asset?.versions[0].permissions.manageApprovalWorkflow;
    const showAWMenuItem = awFeatureEnabled && canManageAW;

    return (
      <div ref={ref} className="assets_card_container">
        <div
          className={classNames(
            'asset_card_wr',
            asset?.deletedAt && 'deleted_asset_card_wr'
          )}
          data-id={asset.id}
        >
          <Tooltip
            title={
              asset.isFavorite ? 'Remove from favorite' : 'Add to favorite'
            }
            placement="bottomRight"
            overlayClassName="toggle_favorite_overlay"
          >
            <div
              className={classNames(
                asset?.deletedAt && 'favorite_icon_deleted',
                asset?.isFavorite ? 'favorite' : 'non_favorite',
                'favorite_icon'
              )}
              onClick={async (e) => {
                if (asset.deletedAt) return;
                if (!asset?.isFavorite) {
                  dispatch(
                    setFavoriteAssetAction(
                      {
                        assetId: asset.id,
                        isFavorite: !asset.isFavorite
                      },
                      page === 'favorite'
                    )
                  );
                  dispatch(
                    updateAssetsWorkspaceCounters(
                      asset.isFavorite ? 'unsetFavorite' : 'setFavorite',
                      asset
                    )
                  );
                  await MixpanelService.track(
                    mixpanel,
                    user.id,
                    MixpanelEventType.MANAGE_ASSETS,
                    {
                      addToFavorite: true,
                      deleteAsset: false,
                      newAsset: false,
                      externalLinkShare: false,
                      approvalRouting: false,
                      newCommentAsset: false,
                      annotationLeft: false,
                      taskFromComment: false
                    }
                  );
                  return;
                }
                dispatch(
                  setFavoriteAssetAction(
                    {
                      assetId: asset.id,
                      isFavorite: !asset.isFavorite
                    },
                    page === 'favorite'
                  )
                );
                dispatch(
                  updateAssetsWorkspaceCounters(
                    asset.isFavorite ? 'unsetFavorite' : 'setFavorite',
                    asset
                  )
                );
                toast(
                  <ActionToast
                    title="Media has been removed from favorites"
                    onUndo={undefined}
                    description={undefined}
                    closeToast={undefined}
                  />,
                  {
                    hideProgressBar: true,
                    bodyClassName: 'toast_container--invitation'
                  }
                );
              }}
            >
              <FavoriteSVG />
            </div>
          </Tooltip>
          {isPastDueDate && (
            <div className="asset_duedate">
              <DueDate urgent={true} date={currentStageDeadline} />
            </div>
          )}
          <Checkbox
            className={classNames(selectedOne && 'show_checkbox')}
            value={asset?.id}
          />
          <div
            className={`assets_card_container__preview ${
              asset?.deletedAt ? 'opacity_card_container' : ''
            }`}
            onClick={() => {
              if (!asset.deletedAt)
                navigate(
                  `/media-viewer/${asset.id}/${
                    asset.asset?.versions?.[0]?.id || ''
                  }`,
                  { state: { returnTo: location.pathname + location.search } }
                );
            }}
          >
            {asset?.deletedAt && (
              <div className="asset_card_deleted_hover">
                <div />
                <Button
                  onClick={() => {
                    dispatch(
                      restoreDeletedAssetAction({
                        assetId: asset.id
                      })
                    );
                    dispatch(updateAssetsWorkspaceCounters('restore', asset));
                    setIsCardMenuVisible(false);
                  }}
                >
                  <RestoreAssetSvg />
                  <span>Restore</span>
                </Button>
              </div>
            )}
            <div ref={assetRef} className="asset-image-container">
              <div className="click_icon">
                <div className="click_icon--overlay" />
                <LottieComponent view="click" className="icon" />
              </div>
              {preparePreview === 'pending' && (
                <Loader
                  classNameWrapper="assets-loader-wrapper"
                  spinSize={40}
                  isSpinning={true}
                />
              )}
              <div
                className="image_block"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseMove={onMouseMove}
              >
                {
                  preparePreview !== 'pending' && preparePreview
                  // <img src={preparePreview} alt="" />
                }

                {scrubsVisible && (
                  <canvas
                    ref={scrubRef}
                    width={frameWidth}
                    height={frameHeight}
                    className="scrubs"
                  />
                )}
                <div ref={scrubLineRef} className="scrub_line" />
              </div>
            </div>
            <div className="preview_info">
              <div className="preview_info_left">
                <div style={asset?.deletedAt ? { visibility: 'hidden' } : {}}>
                  {!!asset.asset?.versions[0].approvalWorkflow && (
                    <Status
                      approvalWorkflow={
                        asset.asset.versions[0].approvalWorkflow
                      }
                    />
                  )}
                </div>
                <div>
                  <Tooltip
                    title={asset.owner?.user?.name}
                    placement="bottom"
                    overlayClassName="toggle_favorite_overlay"
                  >
                    <Avatar
                      src={asset.owner?.user?.picture.url}
                      size={24}
                      style={{
                        color: '#161819',
                        fontWeight: 'bold',
                        backgroundColor: hashedColor(
                          asset.owner?.user?.email ?? '',
                          'mediaViewerComment'
                        )
                      }}
                    >
                      {UserAvatarLetters(asset.owner?.user?.name)}
                    </Avatar>
                  </Tooltip>
                </div>
              </div>
              {(asset.asset?.versions[0].metadata.type === 'audio' ||
                asset.asset?.versions[0].metadata.type === 'video') && (
                <div className="preview_info_duration">
                  {formatDuration(
                    asset.asset?.versions[0].metadata.info.avInfo?.audio
                      ?.durationSeconds ||
                      asset.asset?.versions[0].metadata.info.avInfo?.video
                        ?.durationSeconds ||
                      0
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="assets_card_container__content">
            <div className="assets_card_container__content_body">
              <div className="title">
                <Tooltip
                  placement="topLeft"
                  overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
                  title={`${asset?.asset?.versions[0]?.name}.
                ${asset?.asset?.versions[0]?.extension}`}
                >
                  <span
                    className="copy"
                    onClick={() => {
                      if (!asset.deletedAt)
                        navigate(
                          `/media-viewer/${asset.id}/${
                            asset.asset?.versions?.[0]?.id || ''
                          }`,
                          {
                            state: {
                              returnTo: location.pathname + location.search
                            }
                          }
                        );
                    }}
                  >
                    {asset?.asset?.versions[0]?.name}.
                    {asset?.asset?.versions[0]?.extension}
                  </span>
                </Tooltip>
                <AssetVersionsList
                  versionsCount={asset?.asset?.versionsCount}
                  permissions={asset?.asset?.permissions}
                  versions={asset?.asset?.versions}
                  assetId={asset.id}
                />
              </div>
              <div className="info">
                <span className="info__date">
                  {moment(asset?.modifiedAt).format('MMM DD, YYYY')}
                </span>
                {asset.campaignId && asset.campaignName && isShowCompany && (
                  <div className="info__campaign">
                    <Link
                      to={`/campaigns/single/${asset.campaignId}`}
                      className="info__campaign-link"
                    >
                      {asset.campaignName}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="assets_card_container__content_action">
              <Dropdown
                visible={isCardMenuVisible}
                onVisibleChange={(v) => setIsCardMenuVisible(v)}
                trigger={['click']}
                overlay={
                  <Menu
                    defaultOpenKeys={['']}
                    defaultSelectedKeys={['']}
                    style={{ padding: '19px 12px' }}
                    mode="vertical"
                  >
                    {!asset.deletedAt ? (
                      <>
                        {(asset?.asset?.permissions.createReviews ||
                          canShare) && (
                          <Menu.Item
                            key="share"
                            onClick={() => {
                              dispatch(
                                toggleShareAssetModal({
                                  visible: true,
                                  assets: [{ asset }],
                                  action: 'create'
                                })
                              );
                              setIsCardMenuVisible(false);
                            }}
                          >
                            Share
                          </Menu.Item>
                        )}
                        <Menu.Item
                          key="manage-version-stack"
                          onClick={() => {
                            dispatch(
                              toggleManageAssetVersionsModal({
                                versions: asset?.asset?.versions || [],
                                permissions: asset?.asset?.permissions || null,
                                versionsCount: asset?.asset?.versionsCount || 0,
                                visible: true,
                                assetId: asset.id || ''
                              })
                            );
                            setIsCardMenuVisible(false);
                          }}
                        >
                          Manage version stack
                        </Menu.Item>
                        {showAWMenuItem && (
                          <Menu.Item
                            key="manage-aw"
                            onClick={() => {
                              setIsCardMenuVisible(false);
                              if (!asset.asset?.versions[0]) return;

                              dispatch(
                                toggleApprovalModal({
                                  visible: true,
                                  assetVersion: asset.asset?.versions[0],
                                  allowCreate:
                                    !!asset.asset?.permissions
                                      .createApprovalWorkflows,
                                  isLastVersion: true,
                                  assetId: asset.id,
                                  callback: (asset) => {
                                    dispatch({
                                      type: AssetsActionTypes.UPDATE_ASSET,
                                      payload: { asset }
                                    });
                                  }
                                })
                              );
                            }}
                          >
                            {asset.asset?.versions[0].permissions
                              .manageApprovalWorkflow
                              ? asset.asset?.versions[0].approvalWorkflow
                                ? 'Manage approval routing'
                                : 'Create approval routing'
                              : 'View approval routing'}
                          </Menu.Item>
                        )}

                        <Menu.Item
                          key="add_to_favorites"
                          onClick={async () => {
                            if (asset.deletedAt) return;
                            if (!asset?.isFavorite) {
                              dispatch(
                                setFavoriteAssetAction(
                                  {
                                    assetId: asset.id,
                                    isFavorite: !asset.isFavorite
                                  },
                                  page === 'favorite'
                                )
                              );
                              dispatch(
                                updateAssetsWorkspaceCounters(
                                  asset.isFavorite
                                    ? 'unsetFavorite'
                                    : 'setFavorite',
                                  asset
                                )
                              );
                              await MixpanelService.track(
                                mixpanel,
                                user.id,
                                MixpanelEventType.MANAGE_ASSETS,
                                {
                                  addToFavorite: true,
                                  deleteAsset: false,
                                  newAsset: false,
                                  externalLinkShare: false,
                                  approvalRouting: false,
                                  newCommentAsset: false,
                                  annotationLeft: false,
                                  taskFromComment: false
                                }
                              );
                              setIsCardMenuVisible(false);
                              return;
                            }
                            dispatch(
                              setFavoriteAssetAction(
                                {
                                  assetId: asset.id,
                                  isFavorite: !asset.isFavorite
                                },
                                page === 'favorite'
                              )
                            );
                            dispatch(
                              updateAssetsWorkspaceCounters(
                                asset.isFavorite
                                  ? 'unsetFavorite'
                                  : 'setFavorite',
                                asset
                              )
                            );
                            toast(
                              <ActionToast
                                title="Media has been removed from favorites"
                                onUndo={undefined}
                                description={undefined}
                                closeToast={undefined}
                              />,
                              {
                                hideProgressBar: true,
                                bodyClassName: 'toast_container--invitation'
                              }
                            );

                            setIsCardMenuVisible(false);
                          }}
                        >
                          {asset?.isFavorite
                            ? 'Remove from favorites'
                            : 'Add to favorites'}
                        </Menu.Item>
                        {asset.asset?.versions[0].permissions.download && (
                          <>
                            {asset.asset?.versions[0].metadata.type ===
                            'video' ? (
                              <Menu.SubMenu
                                key="download"
                                title="Download"
                                popupClassName="folders_list"
                                disabled={!foldersList.length}
                              >
                                <Menu.Item
                                  key="original"
                                  onClick={() => {
                                    download(
                                      asset.asset?.versions[0].sourceUrl,
                                      asset.asset?.versions[0].name
                                    );
                                  }}
                                >
                                  Original{' '}
                                  {
                                    asset.asset?.versions[0].metadata.info
                                      .avInfo?.video?.height
                                  }
                                  p
                                </Menu.Item>
                                <Menu.Divider />
                                {Object.entries(
                                  asset.asset?.versions[0].metadata.videoProxies
                                )
                                  .reverse()
                                  .filter((el) => el[1].status !== 'skipped')
                                  .map((el) => {
                                    const info = asset.asset?.versions[0]
                                      .metadata.info as AVMetadataInfoDto;
                                    if (
                                      `${info.avInfo?.video?.height}` === el[0]
                                    )
                                      return;
                                    return (
                                      <Menu.Item
                                        key={el[0]}
                                        disabled={el[1].status !== 'ready'}
                                        onClick={() => {
                                          download(el[1].url);
                                        }}
                                      >
                                        {el[0]}p mp4
                                      </Menu.Item>
                                    );
                                  })}
                              </Menu.SubMenu>
                            ) : (
                              <Menu.Item
                                key="download"
                                disabled={
                                  !asset.asset?.versions[0].permissions.download
                                }
                                onClick={() => {
                                  if (!asset.asset) return;
                                  const { name, sourceUrl } =
                                    asset.asset.versions[0];
                                  download(sourceUrl, name);
                                  setIsCardMenuVisible(false);
                                }}
                              >
                                Download
                              </Menu.Item>
                            )}
                          </>
                        )}
                        {asset.asset?.permissions.rename && (
                          <Menu.Item
                            key="rename"
                            onClick={() => {
                              dispatch(
                                toggleRenameEntityModal({
                                  visible: true,
                                  entity: asset,
                                  page: 'media'
                                })
                              );
                              setIsCardMenuVisible(false);
                            }}
                          >
                            Rename
                          </Menu.Item>
                        )}
                        {(asset.asset?.permissions.moveToPrivateFolder ||
                          asset.asset?.permissions.moveToPublicFolder) && (
                          <Menu.SubMenu
                            key="move_to"
                            title="Move to"
                            popupClassName="folders_list"
                            disabled={!foldersList.length}
                          >
                            {asset.path.length > 0 && (
                              <>
                                <p
                                  style={{
                                    fontSize: 13,
                                    color: '#969696',
                                    margin: '0 0 0 16px'
                                  }}
                                >
                                  CAMPAIGN
                                </p>
                                <Menu.Item
                                  key="campaign"
                                  onClick={async () => {
                                    dispatch(
                                      moveAssetToFolder(
                                        {
                                          assetId: asset.id
                                        },
                                        page === 'campaign',
                                        asset.path
                                      )
                                    );
                                    setIsCardMenuVisible(false);
                                  }}
                                >
                                  {asset.campaignName}
                                </Menu.Item>
                              </>
                            )}
                            {foldersList.length > 1 && (
                              <p
                                style={{
                                  fontSize: 13,
                                  color: '#969696',
                                  margin: '0 0 0 16px'
                                }}
                              >
                                SELECT FOLDER
                              </p>
                            )}
                            {foldersList?.map((el) => {
                              if (asset.path.find((it) => it.id === el.id))
                                return null;
                              return (
                                <Menu.Item
                                  key={el.id}
                                  disabled={
                                    el.isPrivate
                                      ? !asset.asset?.permissions
                                          .moveToPrivateFolder
                                      : !asset.asset?.permissions
                                          .moveToPublicFolder
                                  }
                                  onClick={async () => {
                                    if (el.isPrivate) {
                                      dispatch(
                                        toggleMoveAssetToPrivateFolder({
                                          folderName: el.name,
                                          visible: true,
                                          assetsName: [
                                            asset.asset?.versions[0].name || ''
                                          ],
                                          callback: () => {
                                            dispatch(
                                              moveAssetToFolder(
                                                {
                                                  assetId: asset.id,
                                                  folderId: el.id
                                                },
                                                page === 'campaign',
                                                asset.path
                                              )
                                            );
                                          }
                                        })
                                      );
                                      setIsCardMenuVisible(false);
                                      return;
                                    }
                                    dispatch(
                                      moveAssetToFolder(
                                        {
                                          assetId: asset.id,
                                          folderId: el.id
                                        },
                                        page === 'campaign',
                                        asset.path
                                      )
                                    );
                                    setIsCardMenuVisible(false);
                                  }}
                                >
                                  {el.name.length > 10 ? (
                                    <Tooltip
                                      placement="topLeft"
                                      overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
                                      title={el.name}
                                    >
                                      <span>{el.name}</span>
                                    </Tooltip>
                                  ) : (
                                    <span>{el.name}</span>
                                  )}
                                  {el.isPrivate && <PrivateEye />}
                                </Menu.Item>
                              );
                            })}
                          </Menu.SubMenu>
                        )}
                        {asset.asset?.permissions.delete && (
                          <Menu.Item
                            key="delete"
                            onClick={() => {
                              dispatch(
                                toggleEntityDeletionModal({
                                  entity: asset,
                                  page: 'asset',
                                  permanent: false,
                                  visible: true
                                })
                              );

                              setIsCardMenuVisible(false);
                            }}
                          >
                            Delete
                          </Menu.Item>
                        )}
                      </>
                    ) : (
                      <>
                        {asset.asset?.permissions.delete && (
                          <Menu.Item
                            key="delete_permanent"
                            onClick={() => {
                              dispatch(
                                toggleEntityDeletionModal({
                                  entity: asset,
                                  page: 'asset',
                                  permanent: true,
                                  visible: true
                                })
                              );
                              setIsCardMenuVisible(false);
                            }}
                          >
                            Delete forever
                          </Menu.Item>
                        )}
                        {asset.asset?.permissions.delete && (
                          <Menu.Item
                            key="restore"
                            disabled={!asset.asset?.permissions.delete}
                            onClick={() => {
                              dispatch(
                                restoreDeletedAssetAction({
                                  assetId: asset.id
                                })
                              );
                              dispatch(
                                updateAssetsWorkspaceCounters('restore', asset)
                              );
                              setIsCardMenuVisible(false);
                            }}
                          >
                            Restore
                          </Menu.Item>
                        )}
                      </>
                    )}
                  </Menu>
                }
              >
                <Button type="text" size="small" style={{ padding: 0 }}>
                  <ActionSvg style={{ marginRight: 0 }} />
                </Button>
              </Dropdown>
              {(asset.asset?.versions[0].commentsCount || 0) > 0 &&
                !asset.deletedAt && (
                  <div className="info__comments">
                    <CommentSvg />
                    <span>{asset.asset?.versions[0].commentsCount}</span>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
AssetCard.defaultProps = {
  canShare: false,
  isShowCompany: true
};
export default AssetCard;
