import React, { useEffect, useState } from 'react';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as FreeTrialHasEndedSvg } from '@assets/icons/free_trial_has_ended.svg';
import { Button } from 'antd';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { toggleBillingModal } from '@redux/actions/modalAction';
import { useOrganization } from '@components/OrganizationBoundary';
import isTrialEnd from '@helpers/isTrialEnd';

FreeTrialHasEnded.defaultProps = {
  fullScreen: false
};

const AlertCopies = {
  'members-limit-exceeded':
    'Upgrade to get the most out of StreamWork, or remove extra team members in your account (limit 3) to keep collaborating',
  'asset-summary-size-limit-exceeded':
    'Upgrade to get the most out of StreamWork, or remove extra media in your account (limit 2GB) to keep collaborating'
};

function FreeTrialHasEnded({ fullScreen }: { fullScreen?: boolean }) {
  const { currentOrganization } = useOrganization();
  const dispatch = useDispatch();
  const [alertCopy, setAlertCopy] = useState<string>();
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  useEffect(() => {
    if (!currentOrganization.entity?.owner.me) return;
    if (!currentOrganization.entity.trialEndDate) return;
    const result = isTrialEnd(currentOrganization.entity);

    if (!result) return;

    setAlertCopy(AlertCopies[result]);
    setIsAlertVisible(true);
  }, [currentOrganization]);
  if (!isAlertVisible) return null;
  return (
    <div
      className={classNames(
        'alert-container',
        fullScreen && 'alert-container-full-screen'
      )}
    >
      <div className="info">
        <div className="icon">
          <FreeTrialHasEndedSvg />
        </div>

        <div className="copies">
          <span className="title">Your free trial has ended.</span>
          <span className="description">{alertCopy}</span>
        </div>
      </div>
      <div className="actions">
        <Button
          type="primary"
          onClick={() => dispatch(toggleBillingModal({ visible: true }))}
        >
          Upgrade
        </Button>
        <CrossSvg
          style={{ cursor: 'pointer' }}
          onClick={() => setIsAlertVisible(false)}
        />
      </div>
    </div>
  );
}

export default FreeTrialHasEnded;
