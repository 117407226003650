import React, { useState, useEffect } from 'react';
import { CampaignAdditionalPermissionsDto, CampaignItemDto } from '@api/Api';
import { Switch, Button } from 'antd';
import { ReactComponent as DeleteSvg } from '@assets/icons/delete.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  toggleCampaignInfoModal,
  toggleEntityDeletionModal
} from '@redux/actions/modalAction';
import {
  setCurrentCampaign,
  updateAdditionalPermissions
} from '@redux/actions/campaignAction';

function CampaignSettingsTab({ campaign }: { campaign: CampaignItemDto }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [additionalPermission, setAdditionalPermission] =
    useState<CampaignAdditionalPermissionsDto>(campaign.additionalPermissions);

  useEffect(() => {
    setAdditionalPermission(campaign.additionalPermissions);
  }, [campaign.additionalPermissions]);

  const onToggleAdditionalPermission = async (
    value: boolean,
    target: string
  ) => {
    const newAdditionPermission = {
      ...additionalPermission,
      [target]: value
    };
    setAdditionalPermission(newAdditionPermission);
    await updateAdditionalPermissions({
      campaignId: campaign.id,
      ...newAdditionPermission
    })(dispatch);
  };

  return (
    <>
      <div className="campaign_settigs_tab">
        <div className="campaign_settigs_tab__head">
          <div className="title">Contributor permissions</div>
          <div className="description">
            Set permissions for all contributors invited to this campaign
          </div>
        </div>
        <div className="action">
          <div className="action__title">Can download files</div>
          <div>
            <Switch
              className="campaign_settings_tab__switcher"
              disabled={!campaign.permissions.changeAdditionalPermissions}
              checked={additionalPermission.canDownloadAssets}
              onChange={(e) =>
                onToggleAdditionalPermission(e, 'canDownloadAssets')
              }
            />{' '}
            <span className="main-body-text main-body-text--semibold">
              {additionalPermission.canDownloadAssets ? 'On' : 'Off'}
            </span>
          </div>
        </div>
        <div className="action">
          <div className="action__title">Can invite other contributors</div>
          <div>
            <Switch
              className="campaign_settings_tab__switcher"
              disabled={!campaign.permissions.changeAdditionalPermissions}
              checked={additionalPermission.canInviteContributors}
              onChange={(e) =>
                onToggleAdditionalPermission(e, 'canInviteContributors')
              }
            />{' '}
            <span className="main-body-text main-body-text--semibold">
              {additionalPermission.canInviteContributors ? 'On' : 'Off'}
            </span>
          </div>
        </div>
        <div className="action">
          <div className="action__title">Can share external links</div>
          <div>
            <Switch
              className="campaign_settings_tab__switcher"
              disabled={!campaign.permissions.changeAdditionalPermissions}
              checked={additionalPermission.canShareExternalLinks}
              onChange={(e) =>
                onToggleAdditionalPermission(e, 'canShareExternalLinks')
              }
            />{' '}
            <span className="main-body-text main-body-text--semibold">
              {additionalPermission.canShareExternalLinks ? 'On' : 'Off'}
            </span>
          </div>
        </div>
        <div className="action">
          <div className="action__title">Can upload media to folders only</div>
          <div>
            <Switch
              className="campaign_settings_tab__switcher"
              disabled={!campaign.permissions.changeAdditionalPermissions}
              checked={!additionalPermission.canUploadToRoot}
              onChange={(e) =>
                onToggleAdditionalPermission(!e, 'canUploadToRoot')
              }
            />{' '}
            <span className="main-body-text main-body-text--semibold">
              {!additionalPermission.canUploadToRoot ? 'On' : 'Off'}
            </span>
          </div>
        </div>
      </div>
      <div className="campaign_settigs_tab">
        <div className="delete">
          <strong>Delete campaign</strong>
          <Button
            type="text"
            size="small"
            className="delete__btn"
            disabled={!campaign.permissions.delete}
            onClick={() => {
              dispatch(
                toggleEntityDeletionModal({
                  entity: campaign,
                  page: 'campaign',
                  permanent: false,
                  visible: true,
                  func: () => {
                    dispatch(toggleCampaignInfoModal(null));
                    dispatch(setCurrentCampaign(null));
                    navigate('../');
                  }
                })
              );
            }}
          >
            <DeleteSvg />
            <span>Delete</span>
          </Button>
        </div>
      </div>
    </>
  );
}

export default CampaignSettingsTab;
